import React, { Component } from "react";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./opinions.css";
import Star from '../../assets/Star.svg'

export default function Opinions() {
  const isBrowser = typeof window !== "undefined";
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);
  const [isVertical, setIsVertical] = useState(true);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    if (width > 992) {
      setIsVertical(true);
    } else {
      setIsVertical(false);
    }
  }, [width]);

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: isVertical,
    verticalSwiping: isVertical,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="artists-item">
            <div className="artists-img">
              <StaticImage
                src="../../images/opinions/1.png"
                alt="Opinie"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <div className="artists-desc">
              <p>
                Z przyjemnością polecamy Piotra. Świetnie poprowadził nasze wesele. Goście bawili się od początku do samego końca, mimo bolących nóg. Kontakt z Piotrem bardzo sprawny - przede wszystkim otwarty na wszystkie nasze propozycje. Co ważne, nie narzucał i nie robił nic czego wcześniej wspólnie nie ustaliliśmy. Goście podczas zabawy bardzo chwalili Piotra oraz repertuar. Pełen profesjonalizm i duże doświadczenie - jeszcze raz gorąco polecamy!
              </p>
              <span className="text-second">Daria i Wojciech</span>
              <span className="sub-title" style={{ color: "#fff" }}>
                Warszawa
              </span>
              <div className="stars">
                  <Star className="icon-gold" />
                  <Star className="icon-gold"/>
                  <Star className="icon-gold"/>
                  <Star className="icon-gold"/>
                  <Star className="icon-gold"/>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="artists-item">
            <div className="artists-img">
              <StaticImage
                src="../../images/opinions/2.png"
                alt="Opinie"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <div className="artists-desc">
              <p>
                Już przy pierwszym kontakcie Pan Piotr prezentuje się bardzo profesjonalnie. Dzięki jego pomocy i wskazówkom nasze wesele przeszło najśmielsze oczekiwania. Nie tylko spełnił nasze marzenie, ale przede wszystkim za sprawą wyjątkowo dobranej oferty muzycznej sprawił, że goście bawili się na naszym weselu do białego rana. Pan Piotr jest niezastąpiony. To najlepszy DJ na wesele! Dziękujemy!
              </p>
              <span className="text-second">Zuza i Jan</span>
              <span className="sub-title" style={{ color: "#fff" }}>
                Olkusz
              </span>
              <div className="stars">
                  <Star className="icon-gold" />
                  <Star className="icon-gold"/>
                  <Star className="icon-gold"/>
                  <Star className="icon-gold"/>
                  <Star className="icon-gold"/>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="artists-item">
            <div className="artists-img">
              <StaticImage
                src="../../images/opinions/3.png"
                alt="Opinie"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <div className="artists-desc">
              <p>
                Z ogromną radością mogę polecić usługi Piotra :-) Uśmiechnięty, otwarty i przede wszystkim profesjonalny człowiek, dzięki któremu nasza impreza weselna była wspaniała! Muzyka była taka, jaką właśnie chcieliśmy, na parkiecie ciągle było mnóstwo ludzi, a impreza skończyła się dopiero o 5:30 nad ranem, co świadczy o tym, że wszystkim się bardzo podobało :) Połączenie znanych hitów z dźwiękiem saksofonu nadało imprezie jeszcze fajniejszego wyrazu :) Dziękujemy serdecznie!
              </p>
              <span className="text-second">Agata i Bartek</span>
              <span className="sub-title" style={{ color: "#fff" }}>
                Biłgoraj
              </span>
              <div className="stars">
                  <Star className="icon-gold" />
                  <Star className="icon-gold"/>
                  <Star className="icon-gold"/>
                  <Star className="icon-gold"/>
                  <Star className="icon-gold"/>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}
