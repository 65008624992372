import * as React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header";
import Hero from "../components/hero";
import Opinions from "../components/opinions";
import Contactform from "../components/contactform";
import {StaticImage} from "gatsby-plugin-image";
import Mouse from "../assets/Mouse.svg";
import Arrow from "../assets/Arrow1.svg";
import Play from "../assets/Play.svg";
import Spotify from "../assets/spotify.svg"
import {Helmet} from "react-helmet";
import {Link} from "../../.cache/gatsby-browser-entry";

const Omnie = () => {
    return (
        <Layout>
            <Helmet>
                <title>Poznajmy się - DJ Piotr Szymański</title>
                <meta name="description" content="Poznaj mnie, a na pewno zdecydujesz się ze mną współpracować, jestem osobą miłą oraz bardzo kontaktową, odpowiem wam na najtrudniejsze pytania" />
                <meta http-equiv="Content-type" content="text/html;charset=UTF-8" />
            </Helmet>
            <Hero className="">
                <StaticImage
                    src="../images/hero-about.png"
                    alt="O mnie"
                    placeholder="blurred"
                    layout="fullWidth"
                    className="hero"
                />
                <Header pageName="home"/>
                <div className="hero-title subpage">
                    <h1>DJ na wesele w Warszawa</h1>
                    <h3 className="line-before">Z pasji do muzyki</h3>
                </div>
            </Hero>
            <section className="home-about about-border">
                <div className="about-img">
                    <StaticImage
                        src="../images/about-1.png"
                        alt="Początki"
                        placeholder="blurred"
                        layout="fullWidth"
                        className="home-about-img"
                    />
                </div>
                <div className="about-text about-border">
                    <h2>Jak to się zaczęło? Początki</h2>
                    <Arrow className="arrow-down icon-white arrow-sub-title"/>
                    <p>
                        Swoją przygodę z muzyką rozpocząłem w wieku 17 lat. Przez 8 lat grałem w klubach, a następnie przeniosłem się na wesela oraz eventy, które prowadzę do tej pory. Wiem dobrze, jak zorganizować dobrą zabawę na weselu, i jaki repertuar muzyczny dobrać, by spełnić oczekiwania Pary Młodej oraz gości. Muzyka podczas ceremonii ślubnej jest jednym z najważniejszych aspektów, to od niej bowiem zależy, jak nasi goście będą się bawić i jak zapamiętają nasze wesele. Ważne są nie tylko utwory, jakie będą grane w czasie porywających tańców, ale także ten, przy którym Młoda Para wykona swój pierwszy taniec. Jestem osobą otwartą i kontaktową, co w mojej pracy jest szalenie istotne. Uwielbiam pracę z mikrofonem i z innymi ludźmi. Na imprezach miksuję muzykę na żywo, dzięki doświadczeniu, które wyniosłem z pracy w klubach. W tygodniu realizuję się jako Ul developer, kocham programować. Jestem również spełnionym mężem i ojcem. W wolnych chwilach spędzam aktywnie czas, biegając i grając w piłkę nożną. Zapraszam Cię do siebie i do naszej wspólnej zabawy. Odpowiednio dobrany DJ na wesele to klucz do dobrej zabawy!
                    </p>
                </div>
            </section>
            <section className="about-second">
                <div className="about-text about-border">
                    <h2>Wesele</h2>
                    <span className="text-second">Zorganizuj weselę na miarę Twoich marzeń</span>
                    <Arrow className="arrow-down icon-white arrow-sub-title"/>
                    <p>
                        Nie jest tajemnicą to, że większość osób przychodzi na wesele z dwóch powodów. Po pierwsze, aby dobrze się najeść, a po drugie, aby się wytańczyć, a więc ich ogólne zadowolenie jest zazwyczaj podyktowane satysfakcją z tych dwóch elementów. Wybierając oprawę muzyczną na wesele kierujmy się naszym własnym gustem i oczekiwaniami, jednak weźmy również pod uwagę gości, którzy pojawią się na uroczystości – jeśli towarzystwo będzie mieszane zadbajmy o to, by w repertuarze pojawiły się piosenki nowsze, ale i starsze. Nie wiesz, od czego zacząć wybór oprawy muzycznej? Nie masz pojęcia, na co zwrócić uwagę? Jakiego DJ-a na wesele wybrać? Zastanawiasz się, ile bierze DJ za wesele w Twoim mieście? Omów z nami kwestię wyboru muzyki weselnej i poznaj cennik DJ na wesele. Podczas wstępnej konsultacji weselnej, poznamy Twoje oczekiwania, dowiemy się, w jakim klimacie chcecie zorganizować wesele i wspólnie ustalimy najlepszy repertuar weselny.
                    </p>
                    <p>
                        Zarezerwuj oprawę muzyczną na terenie całej Polski i już dziś umów się na konsultację. DJ na wesele warszawa <Link to={"/kontakt"}>dostępne terminy – sprawdź!</Link>
                    </p>
                </div>
                <div className="about-second-img">
                    <StaticImage
                        src="../images/about-second.png"
                        alt="Lorem ipsum"
                        placeholder="blurred"
                        layout="fullWidth"
                        className="home-about-img"
                    />
                </div>
            </section>
            <section id="opinions" className="artists opinions">
                <Opinions/>
                <div className="artists-title">
                    <h2>Opinie</h2>
                </div>

            </section>
            <section className="spotify">
                <div className="section-title">
                    <h2>Spotify</h2>
                    <h3>Poznaj moją playlistę!</h3>
                    <Spotify className="icon-white"/>
                    <p>Chcesz dowiedzieć się, czego najczęściej słucham na Spofity? Poznaj listę moich ulubionych wykonawców i utworów.  Bądź na bieżąco z tym, co jest na topie!</p>
                </div>
                <div className="spotify-list">
                    <iframe
                        src="https://open.spotify.com/embed/playlist/2FR0NdcXLZhfuyunEi0yuW?utm_source=generator&amp;theme=0"
                        width="100%" height="380" frameBorder="0"
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                </div>
            </section>
            <Contactform/>
        </Layout>
    );
};

export default Omnie;
